import React, {useImperativeHandle, useRef} from 'react'
import {useTranslation} from 'next-i18next'
import WizContentModal from '@component/modal/WizContentModal'
import Text from '@component/Text/Text'
import WizButton from '@component/buttons/WizButton'

interface IProps {
    className?: string
}

const FooterBusinessRegistrationModal = ({className}: IProps, ref: any) => {
    const modalRef = useRef<any>()
    const {t} = useTranslation()

    useImperativeHandle(ref, () => ({
        show: async () => {
            modalRef?.current?.show()
        },
    }))

    return (
        <>
            <WizContentModal size={'400'} title={t('footer.modal.businessRegistrationInfo.title')} ref={modalRef}>
                <div className={'my-[30px]'}>
                    <Text className={'text-gray02 dark:text-dark_gray02'}>
                        {t('footer.modal.businessRegistrationInfo.company')}
                    </Text>
                    <Text className={'text-gray02 dark:text-dark_gray02'}>
                        {t('footer.modal.businessRegistrationInfo.number')}
                    </Text>
                </div>
                <div>
                    <WizButton
                        onClick={() => {
                            modalRef?.current?.close()
                        }}
                        className={'w-full'}
                        buttonType={'border'}
                        text={t('footer.modal.businessRegistrationInfo.close')}
                    />
                </div>
            </WizContentModal>
        </>
    )
}

export default React.forwardRef(FooterBusinessRegistrationModal)
